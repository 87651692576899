@import "@Static/css/common";

$bgColor: getColorValue(1, (0, 0, 0));
$textColor: getColorValue(2, (255, 255, 255));

.root {
    background-color: $bgColor;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
        max-width: 95vw;
    }
}

.title {
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    letter-spacing: 0.74px;
    color: $textColor;
    opacity: 1;
    margin-bottom: 70px;
}

.carouselContainer {
    ul {
        margin: auto;
    }
}

.dotListClass {
    bottom: -2rem;
}

.blogPostsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 70px;
}

.blogLink {
    color: $textColor;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 16px;
}
@media (max-width: 600px) {
    .title {
        font-size: 28px;
        line-height: 39px;
        letter-spacing: 0.45px;
        margin-bottom: 40px;
    }
    .root {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .blogPostsContainer {
        margin-bottom: 35px;
    }
}

@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$textColor: getColorValue(3, (0, 0, 0));

.section1Box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    text-align: center;
}
.section1Title {
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    letter-spacing: 0.74px;
    color: $textColor;
    opacity: 1;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contentBox {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 32px auto 40px;
}
.content {
    width: 266px;
    height: 171px;
    display: flex;
    background-color: $textColor;
    margin-bottom: 62px;
    border-radius: 5px;
}

@media (max-width: 600px) {
    .contentBox {
        flex-direction: column;
        align-items: center;
    }
    .section1Title {
        font-size: 28px;
        line-height: 39px;
        font-weight: bold;
        letter-spacing: 0.45px;
        margin-top: 0px;
    }
}

@import "@Static/css/common";

$primaryColor: getColorValue(1, (47, 106, 103));
$bgColor: getColorValue(2, (255, 255, 255));
$textColor: getColorValue(3, (234, 251, 249));

$cardBgColor: rgb(
    var(--template-page-card-background-host-theme, (255, 255, 25))
);

.root {
    width: 300px;
    padding: 1rem;
    margin: 4px auto;
    box-shadow: #00000014 0px 3px 6px;
    background-color: $cardBgColor;
    color: $textColor;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.2s ease all;
    &:hover {
        transform: translateY(-4px) scale(1.01);
    }
}

.container {
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
    height: 214px;
    background: center no-repeat;
    background-size: cover;
}

.detailsContainer {
    padding: 10px;
    font-size: 0.8rem;
}

.name {
    font-weight: 600;
}

.heading {
    font-weight: 400;
    height: 1.6rem;
    overflow-y: auto;
    color: $textColor;
    @extend .plainScrollbar;
}

.horizontalBar {
    margin: 5px 0;
}
.staffDescription {
    color: $textColor;
    height: 24px;
    overflow: hidden;
}
.description {
    height: 3.2rem;
    overflow-y: auto;
    line-height: normal;
    font-weight: 400;
    @extend .plainScrollbar;
}
.readMoreButton {
    padding: 7px;

    color: $primaryColor;
    margin-right: auto;
    font-size: 16px;
    border-radius: 4px;
    text-decoration: underline;
    transition: 0.2s ease all;
    &:hover {
        filter: brightness(1.1);
    }
}

@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$cardBgColor: rgb(var(--template-page-card-background-host-theme, 0, 0, 0));
$cardTextColor: getColorValue(3, (0, 0, 0));

.container {
    background-color: $cardBgColor;
    border-radius: 4px;
    min-width: 200px;
    width: 350px;
    max-width: calc(100vw - 40px);
    height: 380px;
    min-height: 293px;
    max-height: calc(380px - 202px + calc(202 / 350 * (100vw - 49px)));
    display: flex;
    flex-direction: column;
    margin: 20px;
    line-height: 1.6;
    cursor: pointer;
    transition: 0.2s ease all;
    position: relative;

    &:hover {
        transform: translateY(-4px) scale(1.01);
    }
}

.imageContainer {
    position: relative;
}

.image {
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    background-color: #ddd;

    width: 350px;
    height: 202px;

    min-width: 200px;
    min-height: 115px;

    max-width: calc(100vw - 49px);
    max-height: calc(202 / 350 * (100vw - 49px));
}

.labelsContainer {
    position: absolute;
    top: 10px;
    margin-left: 10px;
    display: flex;
}

.label {
    padding: 2px 6px;
    margin-right: 6px;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;
}

.detailsContainer {
    flex-grow: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    color: $primaryColor;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0px;
    overflow: hidden;
    margin: 0px;
    height: 31px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.amount {
    float: right;
    position: absolute;
}
.shortDescription {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
    color: $cardTextColor;
    opacity: 60%;
    margin: 0 auto;
    height: 19px;
    overflow: hidden;
}

.price {
    color: $primaryColor;
    height: 28px;
    font-size: 24px;
    line-height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.ctahr {
    opacity: 70%;
    margin: 13px 0px;
}
.ctaButton {
    width: 100%;
    background-color: rgb(
        var(--template-block-page-body-text2-host-theme, 34, 82, 81)
    );
    color: $cardBgColor;
    font-size: 16px;
    padding: 7px;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    .amount {
        position: absolute;
    }
}
@media only screen and (max-width: 768px) {
    .amount {
        position: absolute;
    }
}

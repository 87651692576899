@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$cardBgColor: rgb(
    var(--template-page-card-background-host-theme, (255, 255, 25))
);
$textColor: getColorValue(3, (0, 0, 0));

.cardContainer {
    margin: 8px;
    max-width: 99vw;
}

.section2box {
    width: 376px;
    height: 225px;
    display: flex;
    margin-top: 100px;
    background: $cardBgColor 0% 0% no-repeat padding-box;
    border: 2px solid #ccc7;
    border-radius: 5px;
    opacity: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: $textColor;
}
.item1 {
    width: 100%;
    margin-top: 40px;
    padding: 0 12px 0 24px;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 6px;
}
.item2 {
    width: 100%;
    padding: 0 24px 24px;
    text-align: left;
    font-size: 14px;
}
.num {
    font-size: 72px;
    line-height: 52px;
    font-weight: bold;
    letter-spacing: 1.15px;
    color: $primaryColor;
    margin-bottom: -120px;
    padding: 0 0 0 22px;
    text-align: left;
}
@media only screen and (max-width: 767px) {
    .section2box {
        width: 340px;
        max-width: 90vw;
    }
}

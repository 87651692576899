@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$pageBgColor: getColorValue(2, (255, 255, 255));
$cardBgColor: rgb(var(--template-page-card-background-host-theme, 0, 0, 0));
$textColor: getColorValue(3, (0, 0, 0));

.section1Box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    text-align: center;
    max-width: 1240px;
    margin: auto;
}
.section1Title {
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    letter-spacing: 0.74px;
    color: $textColor;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contentBox {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    position: relative;
    z-index: 25;
}
.content {
    width: 266px;
    height: 171px;
    display: flex;
    background-color: $cardBgColor;
    margin-bottom: 62px;
    border-radius: 5px;
    box-shadow: #00000014 0px 3px 6px;
}
.leftItem {
    min-width: 100px;
    width: 100px;
    height: 156px;
    background-color: $primaryColor;
    border-radius: 5px;
    margin: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
div.leftItem::after {
    content: "";
    background-color: $cardBgColor;
    padding: 24px;
    position: absolute;
    margin: auto;
    border-radius: 1000px;
}
.rightItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
}

.count {
    height: 51px;
    color: $primaryColor;
    opacity: 1;
    font-size: 36px;
    line-height: 39px;
    font-weight: bold;
    letter-spacing: 0.58px;
    text-align: left;
}
.program {
    height: 47px;
    color: $primaryColor;
    opacity: 1;
    font-size: 16px;
    line-height: 24px;
    font-weight: medium;
    letter-spacing: 0.26px;
    text-align: left;
    margin-bottom: 29px;
    word-break: break-word;
}
.dotMatrix {
    circle {
        fill: getColorValue(1, (0, 0, 0), 0.3);
    }
    margin-left: 91%;
    z-index: 5;
    position: relative;
    margin-top: -24px;
    margin-bottom: -34px;
}
@media (max-width: 600px) {
    .contentBox {
        flex-direction: column;
        align-items: center;
    }
    .content {
        margin-left: 0px;
        width: 340px;
        margin-bottom: 32px;
        max-width: calc(99vw - 40px);
    }
    .section1Title {
        font-size: 28px;
        line-height: 39px;
        font-weight: bold;
        letter-spacing: 0.45px;
        margin-bottom: 20px;
    }
    .leftItem {
        flex: 0.3;
    }
    .rightItem {
        flex: 0.7;
    }
}
@media (max-width: 1024px) {
    .dotMatrix {
        display: none;
    }
}

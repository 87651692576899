@import "@Static/css/common";
$bgColor: getColorValue(2, (255, 255, 255));

.sectionTitle {
    width: 100%;
    text-align: center;
    margin: 60px 70px;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    color: $bgColor;
    display: flex;
    justify-content: center;
    letter-spacing: 0.74px;
}

@media only screen and (max-width: 600px) {
    .sectionTitle {
        font-size: 36px;
        font-weight: bold;
    }
}

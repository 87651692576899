@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$textColor: getColorValue(3, (0, 0, 0));

.root {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.container {
    position: relative;
}

.title {
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    letter-spacing: 0.74px;
    color: $textColor;
}

.carouselContainer {
    ul {
        margin: auto;
    }
}
.customDot {
    color: $primaryColor;
}
.dotListClass {
    bottom: -2rem;
}
@media (max-width: 600px) {
    .root {
        padding-top: 40px;
    }
    .title {
        font-size: 28px;
        line-height: 39px;
        font-weight: bold;
        letter-spacing: 0.45px;
        margin-bottom: 30px;
    }
}
